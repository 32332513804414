@import "./utils/colors";
@import "./utils/variables";
@import "./utils/breakpoints";
@import "./utils/icons";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');  
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400&display=swap');

html {
  font-size: 62.5%;
}

html,
body {
  min-height: ca;
  padding: 0;
  margin: 0;
  font-family: var(--preferred-font);
  position: relative;
}

input,
textarea {
  font-family: var(--preferred-font);
}

body {
  font-size: 1.6rem;
  overflow-x: hidden;
  touch-action: pan-y;
}

fieldset {
  border: none;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h2, h1, h3, h4, h5, h6, div, button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}