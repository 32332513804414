:root,
:root .dark {
    --unit: 10px;
    --spacing-xs: calc(0.5 * var(--unit));
    --spacing-sm: calc(0.8 * var(--unit));
    --spacing-default: calc(1.5 * var(--unit));
    --spacing-md: calc(2 * var(--unit));
    --spacing-lg: calc(3 * var(--unit));
    --spacing-xl: calc(4 * var(--unit));
    --spacing-xxl: calc(6 * var(--unit));
    --z-under: -1;
    --z-base: 0;
    --z-over: 1;
    --z-over-over: 2;
    --z-high: 3;
    --icon-sm: calc(2 * var(--unit));
    --icon-md: calc(2.7 * var(--unit));
    --icon-md-plus: calc(3.3 * var(--unit));
    --icon-lg: calc(3.6 * var(--unit));
    --border-radius-xs: calc(0.5 * var(--unit));
    --border-radius-sm: calc(0.8 * var(--unit));
    --border-radius-default: var(--unit);
    --border-radius-md: calc(2.5 * var(--unit));
    --border-radius-lg: calc(3 * var(--unit));
    --preferred-font: 'Open Sans', sans-serif;
    //-----------------
    --menu-height: var(--headerHeight);
    --tile-border-inside: calc(1.2 * var(--unit));
    //-----------------
    --transition-2: 0.2s ease-in-out;
    --transition-4: 0.4s ease-in-out;
    --transition-6: 0.6s ease-in-out;
    --transition-8: 0.8s ease-in-out;
    --transition-duration: 0.4s;
    --transition-delay: calc(var(--transition-duration) / 5);
    --transition-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
    --transition-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
    --transition-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
    --transition-curve-default: var(--transition-curve-fast-out-slow-in);
    //----------------
    --shadow-lightest-2p: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    --shadow-lightest-3p: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
    --shadow-lightest-4p: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
    --shadow-lightest-6p: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
    --shadow-lightest-8p: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
    --shadow-lightest-16p: 0 16px 10px 0 rgba(0, 0, 0, 0.3);
    --shadow-lighter-2p: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    --shadow-lighter-3p: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    --shadow-lighter-4p: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    --shadow-lighter-6p: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    --shadow-lighter-8p: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    --shadow-lighter-16p: 0 16px 10px 0 rgba(0, 0, 0, 0.2);
    --shadow-light-2p: 0 0 25px 0 rgba(0, 0, 0, 0.09);
    --shadow-light-3p: 0 3px 25px 0 rgba(0, 0, 0, 0.09);
    --shadow-light-4p: 0 4px 25px 0 rgba(0, 0, 0, 0.09);
    --shadow-light-6p: 0 6px 25px 0 rgba(0, 0, 0, 0.09);
    --shadow-light-8p: 0 8px 25px 0 rgba(0, 0, 0, 0.09);
    --shadow-light-16p: 0 16px 25px 0 rgba(0, 0, 0, 0.09);
    --shadow-2p: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    --shadow-3p: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    --shadow-4p: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    --shadow-6p: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    --shadow-8p: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    --shadow-16p: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    //-----------------
    --standard-increment-mobile: calc(5.6 * var(--unit));
    --standard-increment-desktop: calc(6.4 * var(--unit));
    //----------------- Button
    --button-color-text: var(--color-text);
    --button-border-radius: var(--border-radius-lg);
    --button-height: calc(5 * var(--unit));
    --button-toggle-font-size: calc(2 * var(--unit));
    --button-primary-color: var(--color-primary);
    --button-primary-color-hover: var(--color--lighten);
    --button-primary-color-contrast: var(--color-primary-contrast);
    --button-accent-color-contrast: var(--color-primary-contrast);
    --button-accent-color-hover: var(--color-accent-light);
    --button-accent-color: var(--color-accent);
    --button-neutral-color: var(--color-control-lightest);
    --button-neutral-color-contrast: var(--color-control-darknest);
    --button-neutral-color-hover: var(--color-control-darknest-o75);
    --button-floating-font-size: calc(2.4 * var(--unit));
    --button-floating-height: calc(5.6 * var(--unit));
    --button-floating-mini-height: calc(3.6 * var(--unit));
    --button-floating-mini-font-size: calc(var(--button-floating-mini-height) / 2.5);
    --button-disabled-text-color: color(var(--color-text) a(26%));
    --button-disabled-background-color: color(var(--color-text) a(12%));
    --button-disabled-text-color-inverse: color(var(--color-text) a(54%));
    --button-disabled-background-inverse: color(var(--color-text) a(8%));
    --button-squared-icon-margin: calc(0.6 * var(--unit));
    --button-squared-min-width: calc(9 * var(--unit));
    --button-squared-padding: 0 var(--spacing-default);
    //---------------- Dialog
    --dialog-border-radius: var(--border-radius-md);
    --dialog-color-title: var(--color-text);
    --dialog-color-bg: var(--color-bg-card-darker);
    --dialog-color-header-bg: var(--color-bg-card-invert-lighter);
    --dialog-content-padding: var(--spacing-md);
    --dialog-navigation-padding: calc(0.8 * var(--unit));
    --dialog-translate-y: calc(4 * var(--unit));
    //---------------- Carousel    
    --carousel-height: 200px;
    //---------------- Header 
    --header-height: calc(33 * var(--unit));
    --divider-height: calc(9 * var(--unit));
    --divider-sticky-height: calc(4 * var(--unit));
    --header-sticky-marker-position: calc(10 * var(--unit));
    --header-logo-size: calc(20 * var(--unit));
    --header-logo-sticky-size: calc(var(--header-logo-size) / 2);
    --header-background-height: calc(var(--header-height) * (2/3));
    --header-logo-poisition: calc(var(--header-height) * (1/3));
    --header-logo-sticky-position: calc(var(--header-height) * (1/2));
    --header-sticky-position: calc(-0.4 * var(--header-height));
    --header-buttons-position: calc(6 * var(--unit));
    --header-buttons-sticky-position: calc(var(--header-background-height) + (var(--header-logo-size) / 2) - 44px);
    //---------------- Notification 
    --snackbar-color-cancel: var(--color-alert-critical);
    --snackbar-color-accept: var(--color-alert-success);
    --snackbar-color-warning: var(--color-alert-warning);
    --snackbar-color-error: var(--color-alert-critical);
    --snackbar-background-color: var(--color-bg);
    --snackbar-border-radius: calc(0.2 * var(--unit));
    --snackbar-button-offset: calc(4.8 * var(--unit));
    --snackbar-color: var(--color-text);
    --snackbar-horizontal-offset: 0;
    --snackbar-vertical-offset: calc(1.4 * var(--unit));
    --snackbar-max-width: 400px;
    //---------------- Progress bar 
    --progress-height: calc(0.4 * var(--unit));
    --progress-main-color: var(--color-primary);
    --progress-secondary-color: color(var(--color-primary-contrast) a(70%));
    --progress-disabled-color: color(var(--color-text) a(26%));
    --progress-circle-wrapper-width: 60;
    --progress-circle-radius: 25;
    --progress-scale-ratio: calc(var(--progress-circle-radius) / 20);
    --progress-dashoffset-50: calc(var(--progress-scale-ratio) * 35 * -1);
    --progress-dashoffset-100: calc(var(--progress-scale-ratio) * 124 * -1);
    //---------------- Order
    --order-bar-height: calc(6 * var(--unit));
    --order-action-bar-height: calc(1/3 * var(--unit));
    //----------------  Chip
    --chip-height: calc(1.8 * var(--unit));
    --chip-padding: calc(1.2 * var(--unit));
    --chip-margin-right: calc(1 * var(--unit));
    --chip-background: var(--color-control-light);
    --chip-background-dark: var(--color-control-darker);
    --chip-icon-font-size: calc(1.4 * var(--unit));
    --chip-icon-margin-right: calc(1.2 * var(--unit));
    --chip-color: var(--color-text);
    --chip-color-dark: var(--color-text-invert);
    --chip-remove-size: calc(2.4 * var(--unit));
    --chip-remove-margin: calc(0.4 * var(--unit));
    --chip-remove-stroke-width: calc(0.4 * var(--unit));
    --chip-remove-background: var(--color-control-medium-light);
    --chip-remove-background-hover: var(--color-control-medium);
    --chip-remove-color: var(--color-text-invert);
    //---------------- Restaurant
    --restaurant-aside-width: calc(22 * var(--unit));
    --restaurant-logo-width: calc(20 * var(--unit));
    //---------------- Radio
    --radio-field-margin-bottom: calc(1.5 * var(--unit));
    --radio-button-size: calc(2 * var(--unit));
    --radio-inner-margin: calc(var(--radio-button-size) / 4);
    --radio-inner-color: var(--color-accent);
    --radio-focus-color: color(var(--color-text-invert) a(10%));
    --radio-checked-focus-color: color(var(--color-accent) a(26%));
    --radio-text-color: var(--color-text);
    --radio-disabled-color: color(var(--color-text-invert) a(26%));
    --radio-text-font-size: calc(1.4 * var(--unit));
    //---------------- Checkbox
    --checkbox-color: var(--color-accent);
    --checkbox-disabled-color: color(var(--color-text) a(26%));
    --checkbox-field-margin-bottom: calc(1.5 * var(--unit));
    --checkbox-focus-checked-color: color(var(--color-primary) a(26%));
    --checkbox-ripple-duration: 650ms;
    --checkbox-size: calc(1.8 * var(--unit));
    --checkbox-focus-color: color(var(--color-text-invert) a(1%));
    --checkbox-focus-size: calc(var(--checkbox-size) * 2.3);
    --checkbox-text-color: var(--color-text);
    --checkbox-border-color: var(--color-control-medium-dark);
    --checkbox-total-height: calc(1.8 * var(--unit));
    --checkbox-transition-duration: 0.2s;
    --checkbox-text-font-size: calc(1.4 * var(--unit));
    //--------------------Input
    --input-border-radius: var(--spacing-sm);
    --input-padding: var(--spacing-sm);
    --input-field-padding: var(--spacing-sm);
    --input-focus-label-top: var(--spacing-sm);
    --input-text-background-color: transparent;
    --input-text-label-color: var(--color-text-light);
    --input-text-input-element-color: var(--color-text);
    --input-text-bottom-border-color: var(--color-text);
    --input-text-highlight-color: var(--color-primary);
    --input-text-disabled-color: var(--input-text-bottom-border-color);
    --input-text-disabled-text-color: var(--input-text-label-color);
    --input-text-error-color: var(--color-text-error);
    --input-text-required-color: var(--color-error);
    --input-underline-height: var(--spacing-md);
    --input-icon-font-size: var(--button-floating-mini-font-size);
    --input-icon-size: calc(2 * var(--input-icon-font-size));
    --input-icon-offset: var(--spacing-default);
    --input-icon-right-space: var(--spacing-md);
    --input-chevron-offset: var(--spacing-default);
    --input-hint-opacity: 1;
    //-------------------Autocomplete
    --autocomplete-border-size: calc(var(--unit) / 10);
    --autocomplete-color-primary-contrast: var(--color-primary-contrast);
    --autocomplete-color-primary: var(--color-primary);
    --autocomplete-overflow-max-height: calc(45 * var(--vh));
    --autocomplete-suggestion-active-background: var(--color-control-lighter);
    --autocomplete-suggestion-padding: var(--unit);
    --autocomplete-suggestions-background: var(--color-text-invert);
    --autocomplete-value-margin: calc(var(--unit) * 0.25) calc(var(--unit) * 0.5) calc(var(--unit) * 0.25) 0;
    --autocomplete-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    //---------------------Dropdown
    --dropdown-padding: var(--spacing-md);
    --dropdown-field-padding: var(--spacing-sm);
    --dropdown-field-height: calc(var(--dropdown-field-padding) * 2 + (var(--unit) * 1.4));
    --dropdown-value-border-size: calc(var(--unit) / 10);
    --dropdown-color-white: var(--color-control-medium);
    --dropdown-color-primary: var(--color-control-medium-light);
    --dropdown-color-primary-contrast: var(--color-text-invert);
    --dropdown-color-disabled: color(var(--color-text) a(26%));
    --dropdown-text-label-color: color(var(--color-text) a(26%));
    --dropdown-text-background-color: transparent;
    --dropdown-value-hover-background: var(--color-element-lighter);
    --dropdown-overflow-max-height: 45vh;
    --dropdown-value-border-radius: calc(0.2 * var(--unit));
    --dropdown-underline-height: var(--spacing-md);
    --dropdown-text-error-color: var(--color-text-error);
    --dropdown-text-bottom-border-color: color(var(--color-text) a(12%));
    --dropdown-focus-label-top: var(--spacing-sm);
    --dropdown-chevron-offset: var(--spacing-default);
}
