@import '../common';

.checkboxContainer {
    padding-top: var(--spacing-default);
    margin-bottom: var(--spacing-default);

    &.error .check {
        border-color: var(--color-text-error);
    }

    &.error {
        color: var(--color-text-error);
    }

    .errorDescription {
        @include typo-smallest;

        color: var(--color-text-error);
        margin-left: 66px;
        margin-top: var(--spacing-xs);
    }
}

.field {
    @include reset;

    display: flex;
    margin: 0;
    height: auto;
    margin-bottom: var(--checkbox-field-margin-bottom);
    position: relative;
    white-space: nowrap;

    & .ripple {
        background-color: var(--checkbox-color);
        opacity: 0.3;
        transition-duration: var(--checkbox-ripple-duration);
    }
}

.text {
    font-size: var(--checkbox-text-font-size);
    color: var(--checkbox-text-color);
    display: inline-block;
    line-height: var(--checkbox-size);
    padding-left: var(--unit);
    vertical-align: top;
    white-space: normal;
    width: 100%;
}

.input {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0;

    &:focus ~ .check {
        &::before {
            background-color: var(--checkbox-focus-color);
            border-radius: 50%;
            content: '';
            height: var(--checkbox-focus-size);
            left: 50%;
            margin-left: calc(-1 * var(--checkbox-focus-size) / 2);
            margin-top: calc(-1 * var(--checkbox-focus-size) / 2);
            pointer-events: none;
            position: absolute;
            top: 50%;
            width: var(--checkbox-focus-size);
        }

        &.checked::before {
            background-color: var(--checkbox-focus-checked-color);
        }
    }
}

.check {
    @include reset;

    border-color: var(--checkbox-border-color);
    border-radius: 2px;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    display: inline-block;
    height: var(--checkbox-size);
    position: relative;
    transition-duration: var(--checkbox-transition-duration);
    transition-property: background-color;
    transition-timing-function: var(--transition-curve-default);
    vertical-align: top;
    width: var(--checkbox-size);

    &.checked {
        background-color: var(--checkbox-color);
        border-color: var(--checkbox-color);

        &::after {
            animation: checkmark-expand 140ms ease-out forwards;
            border-bottom-width: 2px;
            border-color: var(--checkbox-text-color);
            border-left: 0;
            border-right-width: 2px;
            border-style: solid;
            border-top: 0;
            content: '';
            height: calc(1.2 * var(--unit));
            left: calc(0.4 * var(--unit));
            position: absolute;
            top: calc(-0.1 * var(--unit));
            transform: rotate(45deg);
            width: calc(0.7 * var(--unit));
        }
    }
}

.disabled {
    & > .text {
        color: var(--checkbox-disabled-color);
    }

    & > .check {
        border-color: var(--checkbox-disabled-color);
        cursor: auto;

        &.checked {
            background-color: var(--checkbox-disabled-color);
            border-color: transparent;
            cursor: auto;
        }
    }
}

@keyframes checkmark-expand {
    0% {
        height: 0;
        left: calc(0.6 * var(--unit));
        top: calc(0.9 * var(--unit));
        width: 0;
    }

    100% {
        height: calc(1.2 * var(--unit));
        left: calc(0.4 * var(--unit));
        top: calc(-0.1 * var(--unit));
        width: calc(0.7 * var(--unit));
    }
}
