:root {
    --color-primary: #fff;
    --color-primary-lighten: #444;
    --color-primary-light: #666;
    --color-primary-contrast: #000;
    --color-secondary: #131415;
    --color-secondary-light: #292b2d;
    --color-secondary-lighten: #3f4346;
    --color-secondary-contrast: #ecebea;
    --color-accent: #dca63d;
    --color-accent-o80: rgba(220, 166, 61, 0.8);
    --color-accent-light: #dfae50;
    --color-accent-lighten: #e3b763;
    --color-text: #212121;
    --color-text-light: rgb(33, 33, 33);
    --color-text-disabled: #757575;
    --color-text-disabled-light: #9e9e9e;
    --color-text-invert: #fff;
    --color-text-invert-disabled: rgba(255, 255, 255, 0.61);
    --color-text-secondary: #a2a2a2;
    --color-text-subdued: #0c0a0a;
    --color-text-subdued-light: #a2a2a2;
    --color-text-caption: #939393;
    --color-text-creamy: #edeef2;
    --color-text-creamy-2: #ECEDF1;
    --color-bc-creamy: #edeef2;
    --color-shadow: #ccc;
    --color-shadow-light: #c5c5c5;
    --color-selected: #e5e5e5;
    --color-menu: #ccc;
    --color-menu-active: #ddd;
    --color-menu-section-active: #ccc;
    --color-subsection-active: #bbb;
    --color-menu-indent: #888;
    --color-menu-icon: #757575;
    --color-bg: #fff;
    --color-bg-dark: #edeef2;
    --color-bg-darkest: #dddee2;
    --color-bg-invert: #131415;
    --color-bg-card: #fff;
    --color-bg-card-dark: #f6f7f9;
    --color-bg-card-invert: #131415;
    --color-bg-card-invert-lighter: #2b2b2b;
    --color-overlay: rgba(0, 0, 0, 0.65);
    --color-overlay-main: rgba(255, 255, 255, 0.9);
    --color-section: rgb(250, 250, 250);
    --color-inactive-without-opacity: rgb(180, 180, 180);
    --color-inactive: rgba(180, 180, 180, 0.8);
    --color-active: #eee;
    --color-inactive-bg: rgba(230, 230, 230, 0.5);
    --color-inactive-bg-light: rgba(255, 255, 255, 0.5);
    --color-inactive-bg-lighten: rgba(255, 255, 255, 0.7);
    --color-inactive-bg-darken: rgba(180, 180, 180, 0.52);
    --color-error: rgb(202, 33, 33);
    --color-error-light: #e76a6a;
    --color-removing-hover: rgba(202, 33, 33, 0.52);
    --color-warning: #ffeb3b;
    --color-warning-light: #fff5a1;
    --color-text-error: #de3226;
    --color-removing: #de3226;
    --color-section-hover: #eee;
    --color-icon: rgba(33, 33, 33, 0.26);
    --color-divider: #dee1e6;
    --color-border-bottom: rgba(33, 33, 33, 0.12);
    --color-border-light: #e0e0e0;
    --color-alert-success: #bbffaa;
    --color-alert-critical: #e09797;
    --color-alert-info: #e4f7ff;
    --color-alert-warning: #fdecb7;
    //control
    --color-control: #2c2c2c;
    --color-control-lightest: #fff; //100
    --color-control-lighter: #eee; //200
    --color-control-light: #e0e0e0; //300
    --color-control-medium-light: #bdbdbd; //400
    --color-control-medium: #9e9e9e; //500
    --color-control-medium-o75: rgba(158, 158, 158, 0.75); //500
    --color-control-medium-dark: #757575; //600
    --color-control-dark: #616161; //700
    --color-control-darker: #424242; //800
    --color-control-darknest: #212121; //900
    --color-control-darknest-o75: rgba(33, 33, 33, 0.75); //900
    --color-control-text: var(--color-text);
    --color-control-text-secondary: var(--color-control-medium-dark);
    --color-control-overlay: #000;
    //base
    --color-error: #de3226;
    --color-text-error: #de3226;
    --color-text-disabled: #a6a6a6;
    --color-disabled: #d6d6d6;
    --color-text-disabled-light: #c6c6c6;
    --color-section-bg: #e7e4e4;
    --color-border-bottom: rgba(180, 180, 180, 0.8);
    //tables
    --color-table-text-gray: #636363;
    --color-table-text-light: #F8F8F8;
    --color-table-text-summary: #9E9E9E;
    //tables edit
    --color-table-text-name: #020202;
    --color-table-text-description: #808080;
    --color-table-text-number: #A2A2A2A2;
    //logo
    --color-logo-bg: #fff;
}

:root .dark  {
    --color-overlay: rgba(255, 255, 255, 0.65);
    --color-overlay-main: rgba(20, 20, 20, 0.9);
    --color-secondary: #fff;
    --color-secondary-lighten: #444;
    --color-secondary-light: #666;
    --color-secondary-contrast: #000;
    --color-primary: #5f5f5f;
    --color-primary-light: #292b2d;
    --color-primary-lighten: #3f4346;
    --color-primary-contrast: #ecebea;
    --color-text: #fff;
    --color-text-light: rgb(180, 180, 180, 0.7);
    --color-text-disabled: #757575;
    --color-text-disabled-light: #9e9e9e;
    --color-text-invert: #212121;
    --color-text-invert-disabled: rgba(21, 21, 21, 0.61);
    --color-text-creamy-2: #252626;
    --color-text-secondary: #595959;
    --color-text-subdued: #696969;
    --color-text-subdued-light: #a2a2a2;
    --color-bg: #131415;
    --color-bg-dark: #252626;
    --color-bc-creamy: #252626;
    --color-bg-darkest: #111;
    --color-bg-invert: #fff;
    --color-bg-card: #131415;
    --color-bg-card-darker: #2b2b2b;
    --color-bg-card-invert: #fff;
    --color-icon: #fff;
    //control
    --color-control: #2c2c2c;
    --color-control-lightest: #000; //100
    --color-control-lighter: #111; //200
    --color-control-light: #1f1f1f; //300
    --color-control-medium-light: #424242; //400
    --color-control-medium: #616161; //500
    --color-control-medium-o75: rgba(97, 97, 97, 0.75); //500
    --color-control-medium-dark: #8a8a8a; //600
    --color-control-dark: #9e9e9e; //700
    --color-control-darker: #bdbdbd; //800
    --color-control-darknest: #dedede; //900
    --color-control-darknest-o75: rgba(222, 222, 222, 0.75); //900
    --color-control-text: var(--color-text);
    --color-control-text-secondary: var(--color-control-medium-dark);
    --color-control-overlay: #000;
}
