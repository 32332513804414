@import '../common';

.button {
    @include reset;
    @include typo-normal;

    align-content: center;
    align-items: center;
    border: 0;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    font-weight: 300;
    height: var(--button-height);
    justify-content: center;
    letter-spacing: 0;
    line-height: var(--button-height);
    outline: none;
    position: relative;
    text-align: center;
    text-decoration: none;
    box-shadow: var(--shadow-light-2p);
    transition: box-shadow 0.1s var(--transition-curve-fast-out-linear-in), transform 0.1s var(--transition-curve-fast-out-linear-in),
    background-color 0.1s var(--transition-curve-default), color 0.1s var(--transition-curve-default);
    white-space: nowrap;

    & > input {
        height: 0.1px;
        margin: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 0.1px;
        z-index: 0;
    }

    &::-moz-focus-inner {
        border: 0;
    }

    & > svg {
        display: inline-block;
        fill: currentColor;
        font-size: 120%;
        height: var(--button-height);
        vertical-align: top;
        width: 1em;
    }

    & > span > svg {
        width: 100%;
        height: 100%;
        stroke: var(--color-text-invert);
    }

    & > * {
        pointer-events: none;
    }

    & > .rippleWrapper {
        overflow: hidden;
    }

    &[disabled] {
        color: var(--button-disabled-text-color);
        cursor: auto;
        pointer-events: none;
    }

    &.mini {
        font-size: var(--button-floating-mini-font-size);
        height: var(--button-floating-mini-height);
        width: var(--button-floating-mini-height);
        padding: var(--spacing-xs);
        display: flex;
        align-items: center;
        justify-content: center;

        & .icon {
            line-height: var(--button-floating-mini-height);
        }
    }
}

.button:active {
    &:not([disabled])
    {
        transform: scale(0.9);
        box-shadow: none;
    }
}

.radius {
    border-radius: var(--button-border-radius);
    min-width: var(--button-squared-min-width);
    padding: var(--button-squared-padding);

    & .icon {
        font-size: 120%;
        margin-right: var(--button-squared-icon-margin);
        vertical-align: middle;
    }

    & > svg {
        margin-right: calc(0.5 * var(--unit));
    }
}

.solid {
    &[disabled] {
        background-color: var(--button-disabled-background-color);
    }
}

.raised {
    composes: button;
    composes: radius;
    composes: solid;
}

.flat {
    background: transparent;
    composes: button;
    composes: radius;
}

.floating {
    border-radius: 50%;
    composes: button;
    composes: solid;
    font-size: var(--button-floating-font-size);
    height: var(--button-floating-height);
    width: var(--button-floating-height);

    & .icon:not([data-sdk='tooltip']) {
        line-height: var(--button-floating-height);
    }

    & > .rippleWrapper {
        border-radius: 50%;
    }
}

.toggle {
    composes: button;

    background: transparent;
    border-radius: 50%;
    vertical-align: middle;
    width: var(--button-height);

    & > .icon,
    & svg {
        font-size: var(--button-toggle-font-size);
        line-height: var(--button-height);
        vertical-align: top;
    }

    & > .rippleWrapper {
        border-radius: 50%;
    }
}

.primary:not([disabled]) {
    @include button-primary;

    &.raised {
        @include button-primary-raised;
    }

    &.floating {
        background: var(--button-primary-color);
        color: var(--button-primary-color-contrast);
    }

    &.toggle {
        color: var(--button-primary-color);

        &:focus:not(:active) {
            background: var(--button-primary-color-hover);
        }
    }
}

:global(.dark) {
    .accent:not([disabled]) {
        @include button-accent;
    
        &.raised {
            @include button-accent-raised;
        }
    
        &.floating {
            background: var(--button-accent-color);
            color: var(--button-accent-color-contrast);
        }
    }

    .accent:disabled {
        @include button-accent;
        opacity: 0.6;
    
        &.raised {
            @include button-accent-raised;

            opacity: 0.6;
        }
    
        &.floating {
            background: var(--button-accent-color);
            color: var(--button-accent-color-contrast);
            opacity: 0.6;
        }
    }

    .neutral:not([disabled]) {
        &.raised,
        &.floating {
            background-color: var(--button-neutral-color-contrast);
            color: var(--button-neutral-color);
        }
    }
  }

.accent:not([disabled]) {
    @include button-accent;
    color: var(--color-text-invert);

    &.raised {
        @include button-accent-raised;
        color: var(--color-text-invert);
    }

    &.floating {
        background: var(--button-accent-color);
        color: var(--button-accent-color-contrast);
    }
}

.accent:disabled {
    @include button-accent;

    color: var(--color-text-invert);
    opacity: 0.6;

    &.raised {
        @include button-accent-raised;
        
        opacity: 0.6;
    }

    &.floating {
        background: var(--button-accent-color);
        color: var(--button-accent-color-contrast);
        opacity: 0.6;
    }
}

.neutral:not([disabled]) {
    &.raised,
    &.floating {
        background-color: var(--button-neutral-color);
        color: var(--button-neutral-color-contrast);
    }

    &.flat,
    &.toggle {
        color: var(--button-neutral-color-contrast);

        &:focus:not(:active) {
            background: var(--button-neutral-color-hover);
        }
    }

    &.flat:hover {
        background: var(--button-neutral-color-hover);
    }

    &.inverse {
        &.raised,
        &.floating {
            background-color: var(--button-neutral-color-contrast);
            color: var(--button-neutral-color);
        }

        &.flat,
        &.toggle {
            color: var(--button-neutral-color);

            &:focus:not(:active) {
                background: var(--button-neutral-color-hover);
            }
        }

        &.flat:hover {
            background: var(--button-neutral-color-hover);
        }
    }
}

.neutral.inverse[disabled] {
    background-color: var(--button-disabled-background-inverse);
    color: var(--button-disabled-text-color-inverse);
}

.button.transparent {
    background: transparent !important;
    box-shadow: none !important;
}
