.container {
    width: calc(6 * var(--unit));
    height: calc(6 * var(--unit));
    border-radius: 50%;
    margin-left: calc(-3.5 * var(--unit));
    margin-top: calc(-3.5 * var(--unit));
    background: var(--color-accent);
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.6);
}

.circle {
    width: calc(6 * var(--unit));
    height: calc(6 * var(--unit));
    max-height: calc(6 * var(--unit));
    max-width: calc(6 * var(--unit));
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

img.image {
    max-width: calc(5.8 * var(--unit));
    max-height: calc(5.8 * var(--unit));
    border-radius: 50%;
}
