@import '../common';

.chip {
    @include typo-smallest;
    @include reset;

    background-color: var(--chip-background);
    border-radius: var(--chip-height);
    color: var(--chip-color);
    display: inline-block;
    line-height: var(--chip-height);
    margin-right: var(--chip-margin-right);
    max-width: 100%;
    overflow: hidden;
    padding: 0 var(--chip-padding);
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.dark {
        background-color: var(--chip-background-dark);
        color: var(--chip-color-dark);
    }

    &.large {
        @include typo-normal;
    }
}

.deletable {
    padding-right: calc(var(--chip-remove-size) + 2 * var(--chip-remove-margin));
}

.delete {
    cursor: pointer;
    display: inline-block;
    height: var(--chip-remove-size);
    margin: var(--chip-remove-margin);
    padding: var(--chip-remove-margin);
    position: absolute;
    right: 0;
    width: var(--chip-remove-size);
    margin: 0;
}

.delete:hover .deleteIcon {
    background: var(--chip-remove-background-hover);
}

.deleteIcon {
    background: var(--chip-remove-background);
    border-radius: var(--chip-remove-size);
    vertical-align: top;

    & .deleteX {
        fill: transparent;
        stroke: var(--chip-remove-color);
        stroke-width: var(--chip-remove-stroke-width);
    }
}
