@import "../common";

.image {
    max-width: 100%;
    max-height: 200px;
    display: none;

    &.isImageVisible {
        display: block;
    }
}

.container {
    position: relative;

    .fileInput {
        display: none;
    }

    .input input {
        cursor: pointer;
        caret-color: transparent;
    }

    .errored input {
        color: var(--color-text-error);
    }

    .clearIcon {
        position: absolute;
        top: calc(2.5 * var(--unit));
        right: 0;
        color: var(--color-text);
        cursor: pointer;
        transition: color var(--transition-2);
        width: 40px;
        height: 40px;

        &:focus,
        &:hover {
            color: var(--color-tex-light);
        }

        &.clearIconOnImage {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.imageContainer {
    position: relative;
    width: fit-content;
}

.cropper {
    position: relative;
    width: 100%;
    min-height: 300px;
    margin-bottom: var(--spacing-lg);
}

.iconOnImage {
    margin-top: var(--spacing-sm);
    background: var(--color-text);
    padding: var(--spacing-xs) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-over);

    span {
        margin: 0 !important;
        color: var(--color-text-invert);
    }
}
