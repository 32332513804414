@import '../utils/typography';
@import '../common';

.dropdown {
    @include reset;

    position: relative;
    z-index: var(--z-over-over);

    &.active > .values {            
        max-height: var(--autocomplete-overflow-max-height);
        opacity: 1;
    }

    &:not(.active) {
        & > .values {
            max-height: 0;
            visibility: hidden;
        }
    }

    &.disabled {
        cursor: normal;
        pointer-events: none;
    }
}

.value {
    border-radius: var(--border-radius-sm);
    background-color: var(--color-control-medium-light);
    box-shadow: var(--shadow-light-2p);

    & > input {
        @include typo-normal;
        
        cursor: pointer;
    }

    &::after {
        border-left: var(--dropdown-value-border-size) solid transparent;
        border-right: var(--dropdown-value-border-size) solid transparent;
        border-top: calc(var(--dropdown-value-border-size) * 2) solid var(--dropdown-text-bottom-border-color);
        content: '';
        height: 0;
        pointer-events: none;
        position: absolute;
        right: var(--dropdown-chevron-offset);
        top: 50%;
        transition: transform var(--transition-duration) var(--transition-curve-default);
        transform: translateY(-50%);
        width: 0;
        z-index: var(--z-high);
    }
}

.field {
    cursor: pointer;
    padding: var(--dropdown-padding) 0;
    position: relative;

    &.errored {
        padding-bottom: 0;

        & > .label {
            color: var(--dropdown-text-error-color);
        }

        & > .templateValue {
            border-bottom: 1px solid var(--dropdown-text-error-color);
        }

        & > .label > .required {
            color: var(--dropdown-text-error-color);
        }
    }

    &.disabled {
        cursor: normal;
        pointer-events: none;

        & > .templateValue {
            border-bottom-style: dotted;
            opacity: 0.7;
        }
    }
}

.withLabel .values {
    top: calc(4.2 * var(--unit));
}

.templateValue {
    background-color: var(--dropdown-text-background-color);
    border-bottom: 1px solid var(--dropdown-text-bottom-border-color);
    color: var(--color-text);
    min-height: var(--dropdown-field-height);
    padding: var(--dropdown-field-padding) 0;
    position: relative;
}

.error {
    @include typo-smallest;

    color: var(--dropdown-text-error-color);
    line-height: var(--dropdown-underline-height);
    margin-bottom: calc(-1 * var(--dropdown-underline-height));
}

.values {
    bottom: auto;
    top: calc(1.5 * var(--unit));
    opacity: 0;
    max-height: 0;
    box-shadow:  var(--shadow-light-2p);
    background-color: var(--color-control-medium-light);
    border-radius: var(--border-radius-sm);
    list-style: none;
    margin: 0;
    overflow-y: auto;
    padding: var(--spacing-lg) 0 0 0;
    position: absolute;
    transition: max-height var(--transition-2), opacity var(--transition-2);   
    width: 100%;
    z-index: var(--z-under);
    
    & > * {
        @include typo-normal;

        cursor: pointer;
        overflow: hidden;
        padding: var(--spacing-sm);
        position: relative;

        &:hover:not(.disabled) {
            background-color: var(--dropdown-value-hover-background);
        }

        &.disabled {
            color: var(--dropdown-color-disabled);
            cursor: not-allowed;
        }
    }
    &::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
}

.expandIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: var(--spacing-default);
    color: var(--color-element);
    display: flex;
    align-items: center;
    transition: transform var(--transition-2);

    .active & {
        transform: rotate(180deg);
    }
}

.clearIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--color-text);
    cursor: pointer;
    transition: color var(--transition-2);
    z-index: var(--z-over-over);

    &:focus,
    &:hover {
        color: var(--color-text-light);
    }
}
